import React, { useState } from 'react';
import EurekaStepCotizacion from '../EurekaStep/EurekaStepCotizacion.tsx';
import EurekaSmartNew from '../estructura/EurekaSmartNew/EurekaSmartNew.tsx';
import { EnviarMensaje, GetEureka, PostEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import $ from 'jquery';
import notiflix from 'notiflix';

interface IFormCotizacion {
  idpais: number;
  cuit?: string;
  idproveedor: number[];
  identrega: number;
  origen: {
    pais: string;
    codigoPostal: string | number;
    provincia: string;
    ciudad: string;
    direccion: string;
    latitud: number;
    longitud: number;
    telefono: string;
    nombreEnvia: string;
    observaciones: string;
  };
  destino: {
    pais: string;
    codigoPostal: string | number;
    provincia: string;
    ciudad: string;
    direccion: string;
    latitud: number;
    longitud: number;
    telefono: string;
    nombreEnvia: string;
    observaciones: string;
  };
  nro_venta: string;
  productos: IProducto[];
  direccion_origen: string;
  direccion_destino: string;
}

interface IProducto {
  valor: string;
  cantidad: number;
  alto: number;
  ancho: number;
  largo: number;
  peso: number;
  articulo: string;
}

interface IListaChecks {
  id: number;
  nroventa: string;
  idsucursal: number;
  idtipoconector: number;
}

interface IValorCotizacion {
  [key: string | number]: any;
  codigoSeguimiento: string;
  descripcion: string;
  idproveedor: number;
  resultado: string;
}

function TabCotizacion() {
  const [datosCotizacionFinal, setdatosCotizacionFinal] = useState([]);
  const [identrega, setidentrega] = useState<IListaChecks[]>([]);
  const [cpManual, setcpManual] = useState({
    origen: true,
    destino: true,
  });
  const [disabledLocalidad, setdisabledLocalidad] = useState({
    direccion_origen: true,
    direccion_destino: true,
  });
  const [combosProvincias, setcombosProvincias] = useState([]);
  const [combosLocalidadesOrigen, setcombosLocalidadesOrigen] = useState([]);
  const [combosLocalidadesDestino, setcombosLocalidadesDestino] = useState([]);
  const [valuesSelect, setvaluesSelect] = useState({
    primer: { label: 'Seleccione', value: 0 },
    segundo: { label: 'Seleccione', value: 0 },
    tercero: { label: 'Seleccione', value: 0 },
    cuarto: { label: 'Seleccione', value: 0 },
  });
  let navItems = ['Proveedores', 'Origen y destino', 'Cotización', 'Envio'];
  let toolbar = {
    showNextButton: true,
    showPreviousButton: false,
    extraHtml: '<button type="button" class="btn btn-secondary" id="resetButton">Resetear</button>',
  };
  let lenguaje = {
    next: 'Siguiente',
    previous: 'Previo',
  };
  let anchor = {
    enableNavigation: false,
  };
  let keyboard = {
    keyNavigation: false,
  };

  const [loading, setLoading] = useState(false);

  const [formCotizacion, setFormCotizacion] = useState<IFormCotizacion[]>([
    {
      idpais: 0,
      nro_venta: '',
      idproveedor: [],
      identrega: 0,
      origen: {
        pais: '',
        codigoPostal: '',
        provincia: '',
        ciudad: '',
        direccion: '',
        latitud: 0,
        longitud: 0,
        telefono: '',
        nombreEnvia: '',
        observaciones: '',
      },
      destino: {
        pais: '',
        codigoPostal: '',
        provincia: '',
        ciudad: '',
        direccion: '',
        latitud: 0,
        longitud: 0,
        telefono: '',
        nombreEnvia: '',
        observaciones: '',
      },
      productos: [],
      direccion_origen: '',
      direccion_destino: '',
    },
  ]);

  const [valorCotizacion, setValorCotizacion] = useState<IValorCotizacion>({
    codigoSeguimiento: '',
    descripcion: '',
    idproveedor: 0,
    resultado: '',
  });

  const [idProveedor, setIdProveedor] = useState({});

  const [arrayProductos, setArrayProductos] = useState([]);

  const [producto, setProducto] = useState<IProducto>({
    articulo: '',
    cantidad: 1,
    peso: 0,
    alto: 1,
    ancho: 1,
    largo: 1,
    valor: '',
  });

  const [mensajeStep3, setMensajeStep3] = useState('');
  const [mensajeStep4, setMensajeStep4] = useState('');

  function funcionDelBotonExtra(wizard: any) {
    const toolbar = wizard.find('.toolbar-bottom');
    const resetButton = toolbar.find('#resetButton');
    const nextButton = toolbar.find('.sw-btn-next');
    resetButton.after(nextButton);
    wizard.on('click', '#resetButton', () => {
      setdisabledLocalidad({
        direccion_origen: true,
        direccion_destino: true,
      });
      setFormCotizacion([
        {
          idpais: 0,
          nro_venta: '',
          idproveedor: [],
          identrega: 0,
          origen: {
            pais: '',
            codigoPostal: 0,
            provincia: '',
            ciudad: '',
            direccion: '',
            latitud: 0,
            longitud: 0,
            telefono: '',
            nombreEnvia: '',
            observaciones: '',
          },
          destino: {
            pais: '',
            codigoPostal: 0,
            provincia: '',
            ciudad: '',
            direccion: '',
            latitud: 0,
            longitud: 0,
            telefono: '',
            nombreEnvia: '',
            observaciones: '',
          },
          productos: [],
          direccion_origen: '',
          direccion_destino: '',
        },
      ]);
      setvaluesSelect({
        primer: { label: 'Seleccione', value: 0 },
        segundo: { label: 'Seleccione', value: 0 },
        tercero: { label: 'Seleccione', value: 0 },
        cuarto: { label: 'Seleccione', value: 0 },
      });
      setcpManual({
        origen: true,
        destino: true,
      });
      setIdProveedor({});
      resetearSmartWizard();
    });
  }

  function resetearSmartWizard() {
    const wizard = $('#smart-dashboard');
    if (wizard.length) {
      wizard.smartWizard('reset');
      wizard.smartWizard('goToStep', 0);
      resetearObjetosLocales();
      setLoading(false);
    }
  }
  function resetearObjetosLocales() {
    let objetoReseteo = {
      idpais: 0,
      idproveedor: [],
      identrega: 0,
      nro_venta: '',
      origen: {
        pais: '',
        codigoPostal: 0,
        provincia: '',
        ciudad: '',
        direccion: '',
        latitud: 0,
        longitud: 0,
        telefono: '',
        nombreEnvia: '',
        observaciones: '',
      },
      destino: {
        pais: '',
        codigoPostal: 0,
        provincia: '',
        ciudad: '',
        direccion: '',
        latitud: 0,
        longitud: 0,
        telefono: '',
        nombreEnvia: '',
        observaciones: '',
      },
      productos: [],
      direccion_origen: '',
      direccion_destino: '',
    };
    setArrayProductos([]);
    setFormCotizacion([objetoReseteo]);
    setIdProveedor([]);
    setProducto({
      articulo: '',
      cantidad: 1,
      peso: 0,
      alto: 1,
      ancho: 1,
      largo: 1,
      valor: '',
    });
  }

  function ejecutarShowStep(wizard: any) {
    wizard.on('showStep', function (e: any, anchorObject: any, stepIndex: number, stepDirection: string) {
      if (stepIndex === 2) {
        notiflix.Report.warning('Advertencia', 'En caso de avanzar al siguiente paso se creara un envio con la cotización seleccionada.', 'Continuar');
      }
    });
  }

  function ejecutarLeaveStep(wizard: any) {
    wizard.off('leaveStep');
    wizard.on('leaveStep', function (e: any, anchorObject: any, currentStepIndex: number, nextStepIndex: number, stepDirection: string) {
      return funcionesBotonSiguiente(currentStepIndex, nextStepIndex, stepDirection, formCotizacion);
    });
  }

  function funcionesBotonSiguiente(currentStepIndex: number, nextStepIndex: number, stepDirection: string, e: any) {
    if (currentStepIndex === 1 && nextStepIndex === 2) {
      let condicion = true;
      let array: any = [];
      formCotizacion.forEach((values) => {
        values.productos.forEach((value) => {
          if (value.hasOwnProperty('alto') && value.hasOwnProperty('ancho') && value.hasOwnProperty('largo') && value.hasOwnProperty('peso') && value.alto > 0 && value.ancho > 0 && value.largo > 0 && value.peso > 0) array.push([value]);
        });
      });
      let arrayCampos = [];
      console.log('FORMI', formCotizacion);
      if (formCotizacion[0]?.productos?.length > 0) {
        if (formCotizacion[0].origen.codigoPostal && formCotizacion[0].destino.codigoPostal && formCotizacion[0].origen.provincia && formCotizacion[0].origen.ciudad && formCotizacion[0].origen.direccion && formCotizacion[0].destino.provincia && formCotizacion[0].destino.ciudad && formCotizacion[0].destino.direccion) {
          formCotizacion[0].origen.codigoPostal = formCotizacion[0].origen.codigoPostal;
          formCotizacion[0].destino.codigoPostal = formCotizacion[0].destino.codigoPostal;
          formCotizacion[0].productos = formCotizacion[0].productos.map((value) => ({ ...value, count: value.cantidad }));
          arrayCampos.push(0);
        } else {
          EnviarMensaje('danger', 'Faltan completar campos en la entrega');
          condicion = false;
        }
      } else {
        EnviarMensaje('danger', 'Esta entrega no puede ser cotizada por proveedor Urbano por falta de medidas en productos.');
        condicion = false;
      }
      if (arrayCampos.length === formCotizacion.length) {
        PostEureka('/Conector/cotizacion', formCotizacion).then((value) => {
          setLoading(true);
          setValorCotizacion({
            ...value.content,
          });
          setLoading(false);
          return false;
        });
      }
      return condicion;
    } else if (currentStepIndex === 2 && nextStepIndex === 3) {
      let condicion = false;
      let proveedores = Object.values(idProveedor || {});
      let newArray: any = [];
      Object.values(idProveedor).forEach((val: any) => {
        formCotizacion.forEach((value, key) => {
          newArray.push({
            idpais: formCotizacion[key].idpais,
            idproveedor: [val.id],
            identrega: formCotizacion[key].identrega,
            cuit: formCotizacion[key].cuit,
            origen: {
              ...formCotizacion[key].origen,
              numeroIdentificacion: '123456',
              nombreTienda: 'FLEXIT',
              numeroTienda: 'C6J695',
            },
            destino: {
              ...formCotizacion[key].destino,
              nombreTienda: 'FLOXET',
            },
            productos: formCotizacion[key].productos,
          });
        });
      });
      if (proveedores?.length && proveedores.filter((value: any) => value.id !== 0).length) {
        condicion = true;
        PostEureka('/conector/envios/crearenvio', newArray).then((response) => setdatosCotizacionFinal(response.content));
      } else {
        EnviarMensaje('danger', 'No selecciono ninguna cotización');
        condicion = false;
      }
      return condicion;
    } else if (currentStepIndex === 0 && nextStepIndex === 1 && stepDirection === 'forward') {
      if (formCotizacion[0]?.idpais === 0 || formCotizacion[0]?.idproveedor?.length === 0) {
        notiflix.Report.failure('Error', 'Debe seleccionar un país y al menos un proveedor', 'Continuar');
        return false;
      } else {
        setFormCotizacion([
          {
            ...formCotizacion[0],
            direccion_origen: formCotizacion[0].idpais === 1 ? 'Argentina' : 'Puerto rico',
            direccion_destino: formCotizacion[0].idpais === 1 ? 'Argentina' : 'Puerto rico',
          },
        ]);
        GetEureka('/coordenadas/combos/provincias/' + formCotizacion[0].idpais).then((value) => {
          setcombosProvincias(value.content);
          return true;
        });
      }
    }
  }

  async function crearCuerpo(cuerpo: IFormCotizacion): Promise<IFormCotizacion> {
    let valuesOrigen = cuerpo.direccion_origen.split(',');
    let valuesDestino = cuerpo.direccion_destino.split(',');
    let newCuerpo = cuerpo;
    const direccionOrigen = cuerpo.direccion_origen + ' ,' + cuerpo.origen.direccion;
    const direccionDestino = cuerpo.direccion_destino + ' ,' + cuerpo.destino.direccion;
    const token:string = localStorage.getItem("token")??"";
    try {
      const datosO = await GetEureka(`/coordenadas/api/coordenadas?token=${token}&direccion=${direccionOrigen.split(',').reverse().join(',')}`);
      const datosD = await GetEureka(`/coordenadas/api/coordenadas?token=${token}&direccion=${direccionDestino.split(',').reverse().join(',')}`);
      console.log(datosO, datosD);
      newCuerpo = {
        ...cuerpo,
        origen: {
          ...cuerpo.origen,
          pais: valuesOrigen[0],
          provincia: valuesOrigen[1],
          ciudad: valuesOrigen[2],
          codigoPostal: String(cuerpo.origen.codigoPostal).length ? cuerpo.origen.codigoPostal : datosO.content.codigoPostal,
          latitud: datosO.content.latitud,
          longitud: datosO.content.longitud,
        },
        destino: {
          ...cuerpo.destino,
          pais: valuesDestino[0],
          provincia: valuesDestino[1],
          ciudad: valuesDestino[2],
          codigoPostal: String(cuerpo.destino.codigoPostal).length ? cuerpo.destino.codigoPostal : datosD.content.codigoPostal,
          latitud: datosD.content.latitud,
          longitud: datosD.content.longitud,
        },
      };
      setFormCotizacion([newCuerpo]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    return newCuerpo;
  }

  return (
    <EurekaSmartNew keyboard={keyboard} funcionesSiguiente={ejecutarLeaveStep} showStep={ejecutarShowStep} navItems={navItems} id='smart-dashboard' botonesToolbar={toolbar} lenguaje={lenguaje} anchor={anchor} tema='arrows' enableSteps={false} btnExtra={funcionDelBotonExtra}>
      <EurekaStepCotizacion 
      isCotizacion={true} 
      identrega={identrega} 
      mensajeStep3={mensajeStep3} 
      cpManual={cpManual} 
      setcpManual={setcpManual}
      crearCuerpo={crearCuerpo} 
      disabledLocalidad={disabledLocalidad} 
      setdisabledLocalidad={setdisabledLocalidad} 
      valuesSelect={valuesSelect} 
      setvaluesSelect={setvaluesSelect} 
      combosProvincias={combosProvincias} 
      combosLocalidadesOrigen={combosLocalidadesOrigen} 
      combosLocalidadesDestino={combosLocalidadesDestino} 
      setcombosLocalidadesOrigen={setcombosLocalidadesOrigen} 
      setcombosLocalidadesDestino={setcombosLocalidadesDestino} 
      formCotizacion={formCotizacion} 
      setFormCotizacion={setFormCotizacion} 
      valorCotizacion={valorCotizacion} 
      setValorCotizacion={setValorCotizacion} 
      loading={loading} 
      setIdProveedor={setIdProveedor} 
      idProveedor={idProveedor} 
      arrayProductos={arrayProductos} 
      setArrayProductos={setArrayProductos} 
      producto={producto} 
      setProducto={setProducto} 
      mensajeStep4={mensajeStep4} 
      datosCotizacionFinal={datosCotizacionFinal} 
      isManual={true}/>
    </EurekaSmartNew>
  );
}

export default TabCotizacion;
