import React from 'react';
import { useForm } from 'react-hook-form';
import { EnviarMensaje, PostEureka } from '../../estructura/EurekaGeneral/EurekaGeneral';

interface IFormMagento {
    modalClose: React.MouseEventHandler<HTMLButtonElement>;
}

const FormMagento: React.FC<IFormMagento> = ({ modalClose }) => {
    const { register, handleSubmit, getValues,setValue, watch } = useForm({
        defaultValues: {
            url_tienda: '',
            username: '',
            password: '',
            token_app: '',
        },
    });
    const watchToken = watch('token_app');
    function onSubmit(e: any) {
        const config = {
            url_tienda: e.url_tienda,
            username: e.username,
            password: e.password,
            token_app: getValues('token_app'),
        };
        PostEureka('/Conector/api/alta_conector', { config: config, conector: 'magento', tipo: 8 })
            .then((response) => {
                if (!response.info) {
                    EnviarMensaje('danger', response.content);
                    modalClose(response);
                } else {
                    EnviarMensaje('success', 'Registro ingresado con éxito');
                    modalClose(response);
                }
            });
    }   
    const generarTokenMgto = () => {
        const body = {
            url_tienda:  getValues('url_tienda'),
            username: getValues('username'),
            password: getValues('password'),
        }
        PostEureka('/Conector/api/magento/obtener_token', body)
            .then((response) => {
                if (!response.info) {
                    EnviarMensaje('danger', response.content);
                } else {
                    EnviarMensaje('success', 'Token generado con éxito');
                    setValue('token_app', response.content);
                }
            });
    }
    return (
        <form className='mt-3' onSubmit={handleSubmit(onSubmit)}>
            <div className='input-group mb-3'>
                <span className='input-group-text' id='basic-addon1'>
                    Url tienda
                </span>
                <input className='form-control' type='text' {...register('url_tienda')} />
            </div>
            <div className='input-group mb-3'>
                <span className='input-group-text' id='basic-addon1'>
                    Username
                </span>
                <input className='form-control' type='text' {...register('username')} />
            </div>
            <div className='input-group mb-3'>
                <span className='input-group-text' id='basic-addon1'>
                    Password
                </span>
                <input className='form-control' type='password' {...register('password')} />
            </div>
            {
                watchToken !== '' ? (
                    <div className='input-group mb-3'>
                        <span className='input-group-text' id='basic-addon1'>
                            Token
                        </span>
                        <input className='form-control' type='text' value={getValues('token_app')} readOnly />
                    </div>
                ) : ''
            }
            <div className='input-group mb-3'>
                <button className='btn btn-primary border' onClick={generarTokenMgto} type='button'>Generar Token</button>
            </div>
            <div className='d-flex justify-content-end'>
                <button className='btn btn-light border' onClick={modalClose}>
                    Cancelar
                </button>
                <button type='submit' className='btn btn-primary ms-3' disabled={watchToken === ''}>
                    Aceptar
                </button>
            </div>
        </form>
    );
};

export default FormMagento;
