import React, { useState } from 'react';
import EurekaModals from '../estructura/EurekaModals/EurekaModals';
import FormML from './FormsConectores/FormML';
import FormTN from './FormsConectores/FormTN';
import FormShopify from './FormsConectores/FormShopify';
import FormWooWix from './FormsConectores/FormWooWix';
import Api from '../../images/conectores/logo_api.png';
import Mc from '../../images/conectores/logo_mercadolibre.png';
import Sho from '../../images/conectores/logo_shopify.png';
import Tn from '../../images/conectores/logo_tiendanube.png';
import Wix from '../../images/conectores/logo_wix.png';
import Woo from '../../images/conectores/logo_woocommerce.png';
import Magento2 from '../../images/conectores/logo_magento2.png';
import FormMagento from './FormsConectores/FormMagento';
const { REACT_APP_API } = process.env;

const TabConectores = () => {
  const [modalConector, setmodalConector] = useState({
    show: false,
    id: 0,
    accion: '',
  });
  const [modalTitulo, setmodalTitulo] = useState('');
  const showModalConector = (id: number) => setmodalConector({ show: true, id: id, accion: '' });
  const closeModal = () => setmodalConector({ show: false, id: 0, accion: '' });
  function manejo_modals(tipo: number) {
    let tituloWixWoo = tipo === 4 ? 'Config. Woocommerce' : 'Config. Wix';
    switch (tipo) {
      case 1: {
        showModalConector(tipo);
        setmodalTitulo('Config. MercadoLibre');
        break;
      }
      case 2: {
        showModalConector(tipo);
        setmodalTitulo('Config. TiendaNube');
        break;
      }
      case 3: {
        showModalConector(tipo);
        setmodalTitulo('Config. Shopify');
        break;
      }
      case 4: {
        showModalConector(tipo);
        setmodalTitulo(tituloWixWoo);
        break;
      }
      case 5: {
        showModalConector(tipo);
        setmodalTitulo(tituloWixWoo);
        break;
      }
      case 6: {
        showModalConector(tipo);
        setmodalTitulo('Config. Magento');
      }
      default:
        return '';
    }
  }
  return (
    <div className='row'>
      <EurekaModals id='modal_conector' sizeModal='lg' manejador={modalConector} modalTitulo={modalTitulo} handleClose={closeModal}>
        {modalConector.id === 1 ? <FormML modalClose={closeModal} /> : modalConector.id === 2 ? <FormTN modalClose={closeModal} /> : modalConector.id === 3 ? <FormShopify modalClose={closeModal} /> : modalConector.id === 4 ? <FormWooWix modalClose={closeModal} id={modalConector.id} /> : modalConector.id === 5 ? <FormWooWix modalClose={closeModal} id={modalConector.id} /> : modalConector.id === 6 ? <FormMagento modalClose={closeModal} /> : ''}
      </EurekaModals>
      {/* poner onClick={() => manejo_modals(1)} para activar de nuevo el proceso de meli */}
      <div onClick={() => manejo_modals(1)} className='col-12 col-md-6 col-lg-4 border-conector d-flex justify-content-center align-items-center'>
        <img className='image-conectores' src={Mc} alt='mercado_libre' />
      </div>
      <div onClick={() => manejo_modals(2)} className='col-12 col-md-6 col-lg-4 border-conector d-flex justify-content-center align-items-center'>
        <img className='image-conectores' src={Tn} alt='tienda_nube' />
      </div>
      <div onClick={() => manejo_modals(3)} className='col-12 col-md-6 col-lg-4 border-conector d-flex justify-content-center align-items-center'>
        <img className='image-conectores' src={Sho} alt='shopify' />
      </div>
      <div onClick={() => manejo_modals(4)} className='col-12 col-md-6 col-lg-4 border-conector d-flex justify-content-center align-items-center'>
        <img className='image-conectores' src={Woo} alt='woocommerce' />
      </div>
      {/* <div className='col-12 col-md-6 col-lg-4 border-conector d-flex justify-content-center align-items-center'>
        <img className='image-conectores' src={Wix} alt='wix' />
      </div> */}
      <div onClick={() => manejo_modals(6)} className='col-12 col-md-6 col-lg-4 border-conector d-flex justify-content-center align-items-center'>
        <img className='image-conectores' src={Magento2} alt='woocommerce' />
      </div>
      <div className='col-12 col-md-6 col-lg-4 border-conector d-flex justify-content-center align-items-center pointer'>
        <a href={`${REACT_APP_API}/documentacion`} target='_blank' rel='noopener noreferrer'>
          <img className='image-conectores' src={Api} alt='api' />
        </a>
      </div>
    </div>
  );
};

export default TabConectores;
