import React, { useEffect, useState } from 'react';
import EurekaTableroObservados from '../../estructura/EurekaTablero/Entregas/EurekaTableroObservados';
import EurekaTableroPendientes from '../../estructura/EurekaTablero/Entregas/EurekaTableroPendientes';
import EurekaTableroFinalizadas from '../../estructura/EurekaTablero/Entregas/EurekaTableroFinalizadas';
import EurekaOffcanvas from '../../estructura/EurekaOffCanvas/EurekaOffCanvas';
import EurekaModals from '../../estructura/EurekaModals/EurekaModals';
import FiltroTableroEntregas from './FiltroEntregas';
import { GetEureka, PostEureka } from '../../estructura/EurekaGeneral/EurekaGeneral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faTableColumns, faTableList } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import FormFormulario from './Forms/FormFormulario';
import EurekaDropdown from '../../estructura/EurekaDropdown/EurekaDropdown';
import FormImportar from './Forms/FormImportarEntregas';
import moment from 'moment';

import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
const { REACT_APP_DIAS_DASHBOARD, REACT_APP_API_URL } = process.env;

var fecha = moment();
const fechaDesde = moment(fecha).subtract(REACT_APP_DIAS_DASHBOARD, 'days').format('YYYY-MM-DD');
const fechaHasta = moment(fecha).format('YYYY-MM-DD');

interface IUseForm {
  fecha_desde: string;
  fecha_hasta: string;
  idtipoconector: number;
  idtipoentrega: number;
  idestado: number;
  nro_venta: string;
  producto: string;
  codinterno: string;
  repartidor: number;
  sucursal: number;
  cliente: number;
  tipo_entrega: number;
  impreso: number;
  es_turbo: number;
  fecha_despacho_inicio: string | null;
  fecha_despacho_fin: string | null;
  fecha_entrega_inicio: string | null;
  fecha_entrega_fin: string | null;
  cancelado: number;
  estado_envio: string | null;
}

interface IArray {
  descripcion: string;
  orden?: number;
  id?: number;
}

interface IArrayDefault {
  label: string;
  value: number | undefined;
}

export default function TabTableroEntregas() {
  const [modalFormulario, setModalFormulario] = useState({ show: false, id: 0, accion: 'f' });
  const ModalCloseFormulario = () => setModalFormulario({ show: false, id: 0, accion: 'f' });
  const ModalShowFormulario = () => setModalFormulario({ show: true, id: 0, accion: 'f' });
  const [modalImportar, setModalImportar] = useState({ show: false, id: 0, accion: 'i' });
  const ModalCloseImportar = () => setModalImportar({ show: false, id: 0, accion: 'i' });
  const ModalShowImportar = () => setModalImportar({ show: true, id: 0, accion: 'i' });
  const [vermas, setVermas] = useState({ observados: true, pendientes: true, finalizados: true });
  const [directionTablero, setDirectionTablero] = useState<boolean>(true);
  const [refresTablero, setRefresTablero] = useState<moment.Moment>();
  const [observados, setObservados] = useState([]);
  const [pendientes, setPendientes] = useState([]);
  const [finalizados, setFinalizados] = useState([]);
  const [defaultEstados, setdefaultEstados] = useState<IArrayDefault[]>([]);
  const [defaultConector, setdefaultConector] = useState<IArrayDefault[]>([]);
  const [defaultClientes, setdefaultClientes] = useState<IArrayDefault[]>([]);
  const [defaultSucursales, setdefaultSucursales] = useState<IArrayDefault[]>([]);
  const [defaultRepartidores, setdefaultRepartidores] = useState<IArrayDefault[]>([]);
  const [defaultEsTurbo, setdefaultEsTurbo] = useState<IArrayDefault[]>([]);
  const [defaultImpreso, setdefaultImpreso] = useState<IArrayDefault[]>([]);
  const [defaultCancelado, setdefaultCancelado] = useState<IArrayDefault[]>([]);
  const [estados, setEstados] = useState<IArray[]>([]);
  const [tipoConector, setTipoConector] = useState<IArray[]>([]);
  const [tipoEntregas, setTipoEntregas] = useState<IArray[]>([]);
  const [clientes, setClientes] = useState<IArray[]>([]);
  const [sucursales, setSucursales] = useState<IArray[]>([]);
  const [repartidores, setRepartidores] = useState<IArray[]>([]);
  const SetRefresh = () => setRefresTablero(moment());
  const SetDirection = () => setDirectionTablero(!directionTablero);
  const { register, getValues, setValue } = useForm<IUseForm>({
    defaultValues: {
      fecha_desde: fechaDesde,
      fecha_hasta: fechaHasta,
      idtipoconector: 0,
      idtipoentrega: 0,
      idestado: 0,
      nro_venta: '',
      producto: '',
      codinterno: '',
      repartidor: 0,
      sucursal: 0,
      cliente: 0,
      tipo_entrega: 0,
      impreso: 3,
      es_turbo: 3,
      fecha_despacho_inicio: null,
      fecha_despacho_fin: null,
      fecha_entrega_inicio: null,
      fecha_entrega_fin: null,
      cancelado: 3,
      estado_envio: null,
    },
  });
  const [offcanvasFiltros, setoffcanvasFiltros] = useState({ show: false });
  const offCanvasFiltrosClose = () => setoffcanvasFiltros({ show: false });
  const offCanvasFiltrosShow = () => setoffcanvasFiltros({ show: true });
  function underline(e: React.MouseEvent<HTMLLabelElement>) {
    const element: any = e.target;
    if (element instanceof SVGElement) {
      element.setAttribute('class', `${element.getAttribute('class')} text-decoration-underline`);
    } else {
      element.className = `${element.className} text-decoration-underline`;
    }
  }

  function noUnderline(e: React.MouseEvent<HTMLLabelElement>) {
    const element: any = e.target;
    if (element instanceof SVGElement) {
      const currentClass = element.getAttribute('class') || '';
      element.setAttribute('class', currentClass.replace('text-decoration-underline', ''));
    } else {
      const currentClassName = element.className || '';
      element.className = currentClassName.replace('text-decoration-underline', '');
    }
  }
  const tooltipDirection = (props: any) => <Tooltip id='button-tooltip' {...props}>{`Cambiar tablero a ${directionTablero ? 'Filas' : 'Columnas'}`}</Tooltip>;
  const tooltipRefresh = (props: any) => (
    <Tooltip id='button-tooltip' {...props}>
      Actualizar tablero
    </Tooltip>
  );
  let arrayVentas = [
    { click: () => ModalShowImportar(), descripcion: 'Via XLS' },
    { click: () => ModalShowFormulario(), descripcion: 'Formulario' },
  ];
  useEffect(() => {
    var formdata = getValues();
    let filtros = localStorage.getItem('filtrosentregas')?.length ? JSON.parse(localStorage.getItem('filtrosentregas') || '{}') : null;
    if (filtros) {
      if (!filtros.hasOwnProperty('nro_venta')) filtros.nro_venta = '';
      if (!filtros.hasOwnProperty('producto')) filtros.producto = '';
      if (!filtros.hasOwnProperty('idestado')) filtros.idestado = 0;
      if (!filtros.hasOwnProperty('idtipoentrega')) filtros.idtipoentrega = 0;
      if (!filtros.hasOwnProperty('idtipoconector')) filtros.idtipoconector = 0;
      if (!filtros.hasOwnProperty('fecha_desde')) filtros.fecha_desde = fechaDesde;
      if (!filtros.hasOwnProperty('fecha_hasta')) filtros.fecha_hasta = fechaHasta;
      if (!filtros.hasOwnProperty('codinterno')) filtros.fecha_hasta = '';
      if (!filtros.hasOwnProperty('repartidor')) filtros.repartidor = 0;
      if (!filtros.hasOwnProperty('sucursal')) filtros.sucursal = 0;
      if (!filtros.hasOwnProperty('cliente')) filtros.cliente = 0;
      if (!filtros.hasOwnProperty('tipo_entrega')) filtros.tipo_entrega = 0;
      if (!filtros.hasOwnProperty('impreso')) filtros.impreso = 3;
      if (!filtros.hasOwnProperty('es_turbo')) filtros.es_turbo = 3;
      if (!filtros.hasOwnProperty('fecha_despacho_inicio')) filtros.fecha_despacho_inicio = null;
      if (!filtros.hasOwnProperty('fecha_despacho_fin')) filtros.fecha_despacho_fin = null;
      if (!filtros.hasOwnProperty('fecha_entrega_inicio')) filtros.fecha_entrega_inicio = null;
      if (!filtros.hasOwnProperty('fecha_entrega_fin')) filtros.fecha_entrega_fin = null;
      if (!filtros.hasOwnProperty('cancelado')) filtros.cancelado = 3;
      if (!filtros.hasOwnProperty('estado_envio')) filtros.estado_envio = '';
      setValue('nro_venta', filtros.nro_venta);
      setValue('producto', filtros.producto);
      setValue('idestado', filtros.idestado);
      setValue('idtipoentrega', filtros.idtipoentrega);
      setValue('idtipoconector', filtros.idtipoconector);
      setValue('fecha_desde', filtros.fecha_desde);
      setValue('fecha_hasta', filtros.fecha_hasta);
      setValue('codinterno', filtros.codinterno);
      setValue('es_turbo', filtros.es_turbo);
      setValue('impreso', filtros.impreso);
      setValue('fecha_despacho_inicio', filtros.fecha_despacho_inicio);
      setValue('fecha_despacho_fin', filtros.fecha_despacho_fin);
      setValue('fecha_entrega_inicio', filtros.fecha_entrega_inicio);
      setValue('fecha_entrega_fin', filtros.fecha_entrega_fin);
      setValue('cancelado', filtros.cancelado);
      setValue('estado_envio', filtros.estado_envio);
      PostEureka('/entregas/tablero', filtros).then((response) => {
        setObservados(response.content.observados);
        setPendientes(response.content.pendientes);
        setFinalizados(response.content.finalizados);
      });
      let estadosArray: { value: number | undefined; label: string }[] = [];
      let conectorArray: { value: number | undefined; label: string }[] = [];
      let clientesArray: { value: number | undefined; label: string }[] = [];
      let sucursalesArray: { value: number | undefined; label: string }[] = [];
      let repartidoresArray: { value: number | undefined; label: string }[] = [];
      let esturboArray: { value: number | undefined; label: string }[] = [];
      let impresoArray: { value: number | undefined; label: string }[] = [];
      let canceladoArray: { value: number | undefined; label: string }[] = [];
      if (typeof filtros.idestado === 'object') {
        estadosArray = estados
          .filter((value) => {
            if (filtros?.idestado?.includes(value.orden)) return value;
          })
          .map((value) => ({ value: value.orden, label: value.descripcion }));
      }
      if (typeof filtros.idtipoconector === 'object') {
        conectorArray = tipoConector
          .filter((value) => {
            if (filtros?.idtipoconector?.includes(value.orden)) return value;
          })
          .map((value) => ({ value: value.orden, label: value.descripcion }));
      }
      if (typeof filtros.cliente === 'object') {
        clientesArray = clientes
          .filter((value) => {
            if (filtros?.cliente?.includes(value.id)) return value;
          })
          .map((value) => ({ value: value.id, label: value.descripcion }));
      }
      if (typeof filtros.sucursal === 'object') {
        sucursalesArray = sucursales
          .filter((value) => {
            if (filtros?.sucursal?.includes(value.id)) return value;
          })
          .map((value) => ({ value: value.id, label: value.descripcion }));
      }
      if (typeof filtros.repartidor === 'object') {
        repartidoresArray = repartidores
          .filter((value) => {
            if (filtros?.repartidor?.includes(value.id)) return value;
          })
          .map((value) => ({ value: value.id, label: value.descripcion }));
      }
      if (filtros.es_turbo === 0 || filtros.es_turbo === 1) {
        esturboArray = [
          { value: 3, label: 'Seleccione' },
          { value: 1, label: 'Si' },
          { value: 0, label: 'No' },
        ]
          .filter((value) => {
            if (value.value === filtros.es_turbo) return value;
          })
          .map((value) => ({ value: value.value, label: value.label }));
      }
      if (filtros.impreso === 0 || filtros.impreso === 1) {
        impresoArray = [
          { value: 3, label: 'Seleccione' },
          { value: 1, label: 'Si' },
          { value: 0, label: 'No' },
        ]
          .filter((value) => {
            if (value.value === filtros.impreso) return value;
          })
          .map((value) => ({ value: value.value, label: value.label }));
      }
      if (filtros.cancelado === 0 || filtros.cancelado === 1) {
        canceladoArray = [
          { value: 3, label: 'Seleccione' },
          { value: 1, label: 'Si' },
          { value: 0, label: 'No' },
        ]
          .filter((value) => {
            if (value.value === filtros.cancelado) return value;
          })
          .map((value) => ({ value: value.value, label: value.label }));
      }
      setdefaultEstados(estadosArray);
      setdefaultConector(conectorArray);
      setdefaultClientes(clientesArray);
      setdefaultSucursales(sucursalesArray);
      setdefaultRepartidores(repartidoresArray);
      setdefaultEsTurbo(esturboArray);
      setdefaultImpreso(impresoArray);
      setdefaultCancelado(canceladoArray);
    } else {
      PostEureka('/entregas/tablero', formdata).then((response) => {
        setObservados(response.content.observados);
        setPendientes(response.content.pendientes);
        setFinalizados(response.content.finalizados);
      });
    }
    GetEureka('/entregas/combos').then((response) => {
      let respuesta = response.content;
      setEstados(respuesta.estados);
      setTipoConector(respuesta.tipo_conector);
      setTipoEntregas(respuesta.tipo_entregas);
      setClientes(respuesta.clientes);
      setSucursales(respuesta.sucursales);
      setRepartidores(respuesta.repartidores);
    });
  }, [refresTablero]);
  return (
    <>
      <EurekaOffcanvas id='tab-entregas' manejador={offcanvasFiltros} offcanvasTitulo='Filtros entregas' posicion='start' handleClose={offCanvasFiltrosClose} size='eureka-offcanvas'>
        <FiltroTableroEntregas
          props={{
            register,
            setValue,
            getValues,
            SetRefresh,
            offCanvasFiltrosClose,
            estados,
            tipoConector,
            tipoEntregas,
            clientes,
            sucursales,
            repartidores,
            defaultEstados,
            defaultConector,
            defaultClientes,
            defaultSucursales,
            defaultRepartidores,
            defaultEsTurbo,
            defaultImpreso,
            defaultCancelado
          }}
        />
      </EurekaOffcanvas>
      <EurekaModals id='modal_formulario' manejador={modalFormulario} modalTitulo='Ingresar novedad' sizeModal='xl' handleClose={ModalCloseFormulario}>
        <FormFormulario setRefresh={SetRefresh} formDisabled={false} handleClose={ModalCloseFormulario}></FormFormulario>
      </EurekaModals>
      <EurekaModals id='modal_importar' manejador={modalImportar} modalTitulo='Proceso importar' sizeModal='xl' handleClose={ModalCloseImportar}>
        <FormImportar></FormImportar>
      </EurekaModals>
      <div className='row'>
        <div className='col-md-1 mb-2'>
          <ButtonGroup size='sm'>
            <Button onClick={offCanvasFiltrosShow} variant='outline-primary'>
              Filtros
            </Button>
            <EurekaDropdown className='rounded-0' id='dropdown-basic' title='Importar' variant='outline-primary' arrayItems={arrayVentas} />
            <OverlayTrigger placement='right' delay={{ show: 150, hide: 150 }} overlay={tooltipRefresh}>
              <Button onClick={SetRefresh} variant='outline-primary'>
                <FontAwesomeIcon icon={faArrowsRotate} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement='right' delay={{ show: 150, hide: 150 }} overlay={tooltipDirection}>
              <Button onClick={SetDirection} variant='outline-primary'>
                <FontAwesomeIcon icon={directionTablero ? faTableList : faTableColumns} />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        </div>
      </div>
      <div className='row'>
        <EurekaTableroObservados vermas={vermas} setVermas={setVermas} direccion={directionTablero} observadosTotal={observados} observados={observados.length > 12 && vermas.observados ? observados.slice(0, 12) : observados} underline={underline} noUnderline={noUnderline} SetRefresh={SetRefresh} />
        <EurekaTableroPendientes vermas={vermas} setVermas={setVermas} direccion={directionTablero} pendientesTotal={pendientes} pendientes={pendientes.length > 12 && vermas.pendientes ? pendientes.slice(0, 12) : pendientes} underline={underline} noUnderline={noUnderline} SetRefresh={SetRefresh} />
        <EurekaTableroFinalizadas vermas={vermas} setVermas={setVermas} direccion={directionTablero} finalizadosTotal={finalizados} finalizados={finalizados.length > 12 && vermas.finalizados ? finalizados.slice(0, 12) : finalizados} underline={underline} noUnderline={noUnderline} SetRefresh={SetRefresh} />
      </div>
    </>
  );
}
